/************************************************************
 * 1) SECTION GLOBALE
 ************************************************************/
 .how-it-works-section {
  padding: 5rem 1rem;
}

/************************************************************
 * 2) CARTOUCHE BLANC AU CENTRE
 ************************************************************/
.how-it-works-container {
  background-color: #fff;
  border-radius: 20px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 4rem 3rem;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
  text-align: center;
}

/************************************************************
 * 3) TITRES
 ************************************************************/
.how-it-works-title {
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
  color: #333;
}

.how-it-works-subtitle {
  font-size: 1.3rem;
  font-weight: 400;
  color: #888;
  margin-bottom: 3.5rem;
}

/************************************************************
 * 4) LAYOUT DES ÉTAPES SUR DESKTOP
 ************************************************************/
.steps-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  position: relative;
}

/* Chaque bloc d'étape */
.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  position: relative;
  z-index: 2;
}

/* Cercle rouge numéroté */
.circle {
  width: 70px;
  height: 70px;
  border: 4px solid #8B181A;
  color: #333;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1.2rem;
  background-color: white;
  transition: all 0.3s ease;
}

/* Add hover effect */
.step:hover .circle {
  background-color: #8B181A;
  color: white;
  transform: scale(1.05);
}

/* Texte de l'étape */
.step-text {
  font-size: 1.1rem;
  color: #444;
  margin: 0;
  font-weight: 500;
}

/* Trait rouge horizontal (desktop) */
.line {
  width: 100px;
  height: 3px;
  background-color: #8B181A;
  position: relative;
  z-index: 1;
}

/************************************************************
 * 5) MEDIA QUERIES
 ************************************************************/

/* === Tablettes : max-width: 768px === */
@media (max-width: 768px) {
  .how-it-works-container {
    padding: 3rem 2rem;
  }
  
  .how-it-works-title {
    font-size: 2.3rem;
  }
  
  .how-it-works-subtitle {
    font-size: 1.2rem;
    margin-bottom: 2.5rem;
  }
  
  .circle {
    width: 60px;
    height: 60px;
    font-size: 1.5rem;
  }
  
  .line {
    width: 60px;
  }
  
  .step {
    width: 150px;
  }
}

/* === Mobiles : max-width: 480px ===
   -> On passe en "timeline" verticale
*/
@media (max-width: 480px) {
  .how-it-works-section {
    padding: 1rem 1rem;
  }

  .how-it-works-container {
    padding: 2.5rem 1.5rem;
  }

  .how-it-works-title {
    font-size: 2rem;
  }
  
  .how-it-works-subtitle {
    margin-bottom: 2rem;
  }

  .steps-row {
    flex-direction: column;
    gap: 0;
    position: relative;
    padding: 20px 0;
    max-width: 280px;
    margin: 0 auto;
  }
  
  /* Add vertical line connecting the steps */
  .steps-row:before {
    content: '';
    position: absolute;
    top: 1;
    bottom: 1;
    left: 50%;
    width: 3px;
    background-color: #8B181A;
    transform: translateX(-50%);
    z-index: 1;
    height: 90%;
  }

  /* Hide horizontal lines */
  .line {
    display: none;
  }

  .step {
    width: 100%;
    margin-bottom: 40px;
    position: relative;
    z-index: 2;
  }
  
  .step:last-child {
    margin-bottom: 0;
  }

  .circle {
    width: 60px;
    height: 60px;
    font-size: 1.4rem;
    z-index: 2;
    
    margin-bottom: 15px;
    position: relative;
    
  }
  
  /* Add connecting elements to the vertical line */
  .step:after {
    content: '';
    position: absolute;
    top: 30px; /* Half the circle height */
    left: 50%;
    width: 20px;
    height: 20px;
    background-color: #8B181A;
    border-radius: 50%;
    transform: translateX(-50%);
    z-index: 1;
    opacity: 0;
  }
  
  .step-text {
    font-size: 1.1rem;
    margin-top: 0.5rem;
    font-weight: 500;
    background-color: white;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
}

/* === Très petits mobiles === */
@media (max-width: 360px) {
  .how-it-works-container {
    padding: 2rem 1rem;
  }
  
  .how-it-works-title {
    font-size: 1.8rem;
  }
  
  .how-it-works-subtitle {
    font-size: 1rem;
  }
  
  .circle {
    width: 50px;
    height: 50px;
    font-size: 1.2rem;
  }
  
  .step-text {
    font-size: 1rem;
  }
  
  .steps-row {
    max-width: 240px;
  }
}